import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import rootReducer from '../reducers/reducers';

export const middleware = [thunk];

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// https://github.com/reduxjs/redux-toolkit/issues/587
