'use client';
import React from 'react';
import Script from 'next/script';

import { ThirdPartyScriptsPropTypes } from './ThirdPartyScripts.types';

declare global {
  interface Window {
    gsap?: any;
    ScrollTrigger?: any;
  }
}

const ThirdPartyScripts: React.FC<ThirdPartyScriptsPropTypes> = () => {
  // Function to register ScrollTrigger plugin
  const handleLoadScrollTrigger = () => {
    if (window.gsap && window.ScrollTrigger) {
      window.gsap.registerPlugin(window.ScrollTrigger);
    }
  };

  return (
    <>
      {/* GSAP Scripts */}
      <Script
        src='https://cdn.jsdelivr.net/npm/gsap@3/dist/gsap.min.js'
        strategy='afterInteractive'
      />
      <Script
        src='https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js'
        strategy='afterInteractive'
        onLoad={handleLoadScrollTrigger}
      />
    </>
  );
};

export default ThirdPartyScripts;
