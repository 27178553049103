import { combineReducers } from 'redux';

import loaderReducer from './loaderReducer/loaderReducer';
import windowDimensionReducer from './windowDimensionReducer/windowDimensionReducer';

const rootReducer = combineReducers({
  windowRXS: windowDimensionReducer,
  loaderRXS: loaderReducer,
});

export default rootReducer;
